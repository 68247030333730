const networkInfo = [
  {
    id: 1,
    name: "Ethereum",
    chainId: 1,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    contractAddress: "0xe8a45f9c0224C4Ef5501128BAd3C9415E32404ad",
    routerAddress: "0xe8a45f9c0224C4Ef5501128BAd3C9415E32404ad",
    blockExplorer: "https://etherscan.io",
    fee: 90000000000000000,
    etherscanApiUrl: "https://api.etherscan.io/api",
    etherscanApiKey: "TH9HH15A3GEC77A3ZX7IW9NC4IJ31JCRWC",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
    exchangeName: "Uniswap",
  },
  {
    id: 56,
    name: "BSC",
    chainId: 56,
    currencySymbol: "BNB",
    currencyName: "BNB Coin",
    contractAddress: "0xC91D1ceD4f78af4D0314B613F953437ABf4f8132",
    routerAddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
    blockExplorer: "https://bscscan.com",
    fee: 350000000000000000,
    etherscanApiUrl: "https://api.bscscan.com/api",
    etherscanApiKey: "XVH5ZZ5J6J2CMA2M6WJ3T9P27BX45KDIGB",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD",
    exchangeName: "Pancakeswap",
  },
  {
    id: 250,
    name: "Fantom",
    chainId: 250,
    currencySymbol: "FTM",
    currencyName: "Fantom Opera",
    contractAddress: "0x4df1568d916B94AE0A3aa8f68111eC4844b2b0a2",
    routerAddress: "0x5023882f4D1EC10544FCB2066abE9C1645E95AA0",
    blockExplorer: "https://ftmscan.com",
    fee: 199000000000000000000,
    etherscanApiUrl: "https://api.ftmscan.com/api",
    etherscanApiKey: "BYBEEEKCI4FKYY876AXMQSBXSMX7CNW683",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=FTM&tsyms=USD",
    exchangeName: "WigoSwap",
  },
  {
    id: 137,
    name: "Polygon",
    chainId: 137,
    currencySymbol: "MATIC",
    currencyName: "MATIC",
    contractAddress: "0xE749B019DECc37507c2Bd8Ce822EDCAab6F13fc5",
    routerAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    blockExplorer: "https://polygonscan.com",
    fee: 99000000000000000000,
    etherscanApiUrl: "https://api.polygonscan.com/api",
    etherscanApiKey: "WKE33UY9HVRA68971249R8SZNUB4KD9T4S",
    priceApi:
      "https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD",
    exchangeName: "Quickswap",
  },
];

export default networkInfo;
