import React, { useState } from "react";

function InfoInput({ link, label, onChange, name, value, disabled }) {
  const [focused, setFocused] = useState(false);

  return (
    <>
      {label && <label className="mt-4">{label}</label>}
      <div
        className="row"
        style={{
          borderRadius: "20px",
          border: `${focused ? "7px" : "5px"} solid ${
            focused ? "#5c27fe" : "gray"
          }`,
          padding: 0,
          margin: "2px",
        }}
      >
        <div
          className="col-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <p style={{ fontSize: "1.2rem", margin: 0, textAlign: "right" }}>
            {link}
          </p>
          <input
            type="text"
            className="form-control"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoComplete="off"
            style={{
              width: "100%",
              border: "none",
              background: "transparent",
              boxShadow: "none",
              backdropFilter: "none",
              fontSize: "1.2rem",
              "&:focus": {
                border: "none",
                background: "transparent",
                boxShadow: "none",
                backdropFilter: "none",
                paddingRight: "80px",
                justifyContent: "flex-end",
              },
            }}
            onChange={onChange}
            name={name}
            value={value}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}

export default InfoInput;
