import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/layouts/banner.png";

function Banner(props) {
  return (
    <section className="banner">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h4 className="heading">Welcome to CastCrypto, </h4>
                <h4 className="heading">
                  {" "}
                  your gateway to seamless{" "}
                  <span className="s1 arlo_tm_animation_text_word">
                    token creation{" "}
                  </span>
                  <br />
                  in under 30 seconds.
                </h4>{" "}
                <br />
                <h5 className="desc">
                  Our platform provides a secure, fast, and professional
                  environment for users of all levels – from crypto enthusiasts
                  to seasoned developers. With CastCrypto, you can effortlessly
                  craft your unique tokens. Explore the simplicity and potential
                  of token creation with CastCrypto – where innovation meets
                  accessibility.
                </h5>
                {/* <p>Welcome to CastCrypto, your gateway to seamless token creation in under 30 seconds. Our platform provides a secure, fast, and professional environment for users of all levels – from crypto enthusiasts to seasoned developers. With CastCrypto, you can effortlessly craft your unique tokens. Explore the simplicity and potential of token creation with CastCrypto – where innovation meets accessibility.</p> */}
                <Link to="/createtoken" className="action-btn">
                  <span>Create Token</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div
                className="image"
                style={{ maxWidth: "100%", height: "auto" }}
              >
                <img
                  src={img1}
                  alt="cyfonii"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
