import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img4 from "../../assets/images/layouts/3.png";
import img5 from "../../assets/images/layouts/icon21.png";
import { removeDecimal } from "../../mycomponents/helpers";
import networkInfo from "../../mycomponents/networks";

function Speciality(props) {
  const [ethPrice, setEthPrice] = useState([]);
  const [priceInfo, setpriceInfo] = useState(null);

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        var pricess = [];
        networkInfo.forEach((e) => {
          const setPrices = async () => {
            const usdPrice = await axios.get(e.priceApi);
            pricess.push({
              symbol: e.currencySymbol,
              price: removeDecimal(e.fee, 18, 2),
              usdprice: usdPrice.data.USD,
            });
          };
          setPrices();
        });
        setpriceInfo(pricess);

        const url =
          "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
        const res = await axios.get(url);
        setEthPrice((Number(res.data.USD) * 0.09).toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    fetchEthPrice();
  }, []);
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Our Speciality",
    heading: "Complete Solutions for your NFT",
    desc: "Cyfonii is the premier marketplace for nifties, which are digital items you can truly own for yourself",
  });

  return (
    <section className="speciality">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>TOKEN</span>
              </h6>
              <h3 className="heading pd">Token Options</h3>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-md-6 mb-4">
                <div className="speciality-box" style={{ height: "400px" }}>
                  <div className="icon">
                    <img src={img4} alt="" />
                  </div>
                  <h5 className="title">Token PRO</h5>
                  <p>
                    Professional & Protected Contract <br />
                    Flexible Buy/Sell Tax (0-25%) <br />
                    Creators' Social Media Links in Contract Code <br />
                    Anti-Bot Measures <br />
                    Team Wallet Distribution Pre-launch <br />
                    Immediate Trading Availability <br />
                    Liquidity Burning Option (On/Off) <br />
                  </p>
                  <br />
                  {priceInfo &&
                    priceInfo.map((e) => (
                      <React.Fragment key={e.symbol}>
                        <p>
                          Cost:
                          {" " +
                            e.price +
                            " " +
                            e.symbol +
                            " (" +
                            (Number(e.usdprice) * Number(e.price)).toFixed(2) +
                            "$)"}
                        </p>
                      </React.Fragment>
                    ))}

                  <Link to="/createtoken" className="action-btn">
                    <span>Create token</span>
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 mb-4">
                <div className="speciality-box" style={{ height: "400px" }}>
                  <div className="icon">
                    <img src={img5} alt="" />
                  </div>
                  <h5 className="title">Token FREE</h5>
                  <p>
                    Professional & Protected Contract <br />
                    Flexible Buy/Sell Tax (1%-25%) <br />
                    Creators' Social Media Links in Contract Code <br />
                    Anti-Bot Measures <br />
                    Team Wallet Distribution Pre-launch <br />
                    Immediate Trading Availability <br />
                    Liquidity Burning Option (On/Off) <br />
                    (CastCrypto charging 1% from Buy/Sell taxes)
                    <br />
                  </p>
                  {priceInfo &&
                    priceInfo.map((e) => (
                      <React.Fragment key={e.symbol}>
                        <p>
                          Cost:
                          {" 0 " + e.symbol + " (0$)"}
                        </p>
                      </React.Fragment>
                    ))}
                  <button
                    className="action-btn"
                    style={{ cursor: "none", backgrounColor: "gray" }}
                  >
                    <span>Coming Soon</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Speciality;
