import React, { useState } from "react";

import { Link } from "react-router-dom";

import data from "../../assets/fake-data/data-blog";

import { toast } from "react-toastify";

const data1 = [
  { id: 0, img: "/assets/icons/setup.png", title: "Token Info" },
  { id: 1, img: "/assets/icons/distribution.png", title: "Distribution" },
  { id: 2, img: "/assets/icons/antibot.png", title: "Anti Bot" },
  { id: 3, img: "/assets/icons/taxes.png", title: "Tax" },
  { id: 4, img: "/assets/icons/liquidity.png", title: "Initial Liquidity" },
];

function Sidebar(props) {
  const {
    setStep,
    step,
    data,
    isOpen,
    isSellTaxOpen,
    isBuyTaxOpen,
    isUniswapOpen,
  } = props;

  const handleNext = (e) => {
    if (
      step === 0 &&
      (!data.tokenname || !data.tokensymbol || !data.totalsupply)
    ) {
      toast.error("Please fill all required fields");
    } else if (step === 1 && isOpen && !data.teamAddress) {
      toast.error("Please fill all required fields");
    } else if (
      step === 3 &&
      (isSellTaxOpen || isBuyTaxOpen) &&
      !data.taxWallet
    ) {
      toast.error("Please fill all required fields");
    } else if (step === 4 && isUniswapOpen && !Number(data.liquidityEth)) {
      toast.error("Please fill all required fields");
    } else {
      setStep(e);
    }
  };

  return (
    <div className="sidebar">
      <div className="widget-sidebar recent">
        <h5 className="heading">Create Token</h5>

        <ul>
          {data1.map((idx, index) => (
            <li
              key={idx.id}
              onClick={() => {
                handleNext(idx.id);
              }}
            >
              <div className="image">
                <a>
                  <img src={idx.img} alt="" />
                </a>
              </div>

              <div className="content mt-2">
                <a
                  to="#"
                  className="title"
                  style={{ color: step === idx.id ? "#5C27FE" : "#fff" }}
                >
                  {idx.title}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
