import { React, useEffect } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";

import routes from "./pages";
import Page404 from "./pages/404";
import Header from "./components/header/Header";
import Home01 from "./pages/Home01";
import Team from "./pages/Team";
import BlogDetails from "./pages/BlogDetails";
import { WagmiConfig, createConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mainnet, bsc, fantom, polygon } from "@wagmi/core/chains";
import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig,
} from "connectkit";
import info from "./info";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "AW-11447002635",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const config = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "t9D5SvCojoZxdBFxiqoaz_48ftzcxGdL", // or infuraId
      walletConnectProjectId: "2759cec704b744be7772ee89f0b593b3",

      // Required
      appName: "CastCrypto",

      // Optional
      chains: [mainnet, bsc, fantom, polygon],
      appDescription: "Create Your Tokens",
    })
  );
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <WagmiConfig config={config}>
        <ConnectKitProvider>
          <Header />

          <Routes>
            <Route path="/" element={<Home01 />} />

            <Route path="/createtoken" element={<BlogDetails />} />

            {/* <Route path="/menage" element={<Team />} /> */}
          </Routes>
        </ConnectKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
