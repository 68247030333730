import React from "react";
import { useChains, ChainIcon } from "connectkit";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { toast } from "react-toastify";
import { useEffect } from "react";

function SwitchNetwork() {
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    console.log(chains, chain);
  }, [chains, chain]);

  return (
    <div
      className=" mb-5"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {chains.map((x) => (
        <button
          disabled={!switchNetwork || x.id === chain?.id || !isConnected}
          key={x.id}
          onClick={() =>
            address
              ? switchNetwork?.(x.id)
              : toast.error("You need to connect your wallet")
          }
          className="action-btn mb-3"
          style={{
            marginRight: "5px",
            color: chain?.id === x.id && "rgb(92, 39, 254)",
          }}
        >
          <ChainIcon id={x.id} />
          {x.name + " "}
          {isLoading && pendingChainId === x.id && " (switching)"}
        </button>
      ))}
    </div>
  );
}

export default SwitchNetwork;
