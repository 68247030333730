const dev = true;

const devInfo = {
  dev: true,
  contractAddress: "0x3676eD1320b92B3C81D112c335EADaa983fFA180",
  routerAddress: "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008",
  fee: 90000000000000000,
  apiUrl: "https://api.castcrypto.com",
  etherscanApiUrl: "https://api-sepolia.etherscan.io/api",
  etherscanApiKey: "TH9HH15A3GEC77A3ZX7IW9NC4IJ31JCRWC",
};

const mainInfo = {
  dev: false,
  contractAddress: "0xE705D9BaEc58A680bD8281B0303B9955add5145c",
  routerAddress: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
  fee: 90000000000000000,
  apiUrl: "https://api.castcrypto.com",
  etherscanApiUrl: "https://api.etherscan.io/api",
  etherscanApiKey: "TH9HH15A3GEC77A3ZX7IW9NC4IJ31JCRWC",
};

const info = dev ? devInfo : mainInfo;

export default info;
