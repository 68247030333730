const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },

    {
        id: 4,
        name: 'Create Token',
        links: '/createtoken',
        // links: '/blogdetails',

    },


]

export default menus;