import React from "react";
import { useState } from "react";

function Input({
  label,
  placeholder,
  required,
  onChange,
  name,
  value,
  disabled,
}) {
  const [focused, setFocused] = useState(false);
  return (
    <div className="col">
      {label && (
        <label>
          {label}
          <span style={{ color: "red" }}>{required && " *"}</span>
        </label>
      )}
      <input
        type="text"
        className="form-control"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        autoComplete="off"
        style={{
          background: "transparent",
          borderRadius: "20px",
          padding: "12px 15px",
          fontWeight: "300",
          fontSize: "14px",
          fontFamily: '"Readex Pro"',
          boxShadow: "none",
          color: "#fff",
          outline: "none",
          border: focused ? "7px solid #5c27fe" : "5px solid gray",
          fontSize: "1.2rem",
        }}
        placeholder={placeholder && placeholder}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

export default Input;
