import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { useAccount, useNetwork } from "wagmi";
import InfoInput from "../mycomponents/InfoInput";
import Input from "../mycomponents/Input";
import { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import { useWaitForTransaction, useContractWrite } from "wagmi";
import { abi1 } from "../mycomponents/factoryContract";
import info from "../info";
import { erc20ABI, multicall } from "@wagmi/core";

import {
  addDecimal,
  encodeConstructorArguments,
  getCode,
  removeDecimal,
  verifyContract,
} from "../mycomponents/helpers";
import PageTitle from "../components/pagetitle/PageTitle";
import { toast } from "react-toastify";
import ConnectButton from "../mycomponents/ConnectButton";
import { useContractRead } from "wagmi";
import SwitchNetwork from "../mycomponents/SwitchNetwork";
import networkInfo from "../mycomponents/networks";

function BlogDetails(props) {
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const [screen, setscreen] = useState(0);
  const [focusedTextArea, setFocusedTextArea] = useState(false);
  const [deploymentStatus, setDeploymentStatus] = useState(
    "Transaction starting"
  );
  const [readEnable, setReadEnable] = useState(false);
  const [deploymentLoading, setDeploymentLoading] = useState(true);
  const [deploymentDesc, setDeploymentDesc] = useState("");

  const [currentNetwork, setCurrentNetwork] = useState(null);

  useEffect(() => {
    if (address && isConnected) {
      const net = networkInfo.filter((e) => e.id == chain?.id)[0];
      setCurrentNetwork(net);
    }
  }, [address, isConnected, chain]);

  const {
    data: deployData,
    error: deployError,
    write: deployTx,
  } = useContractWrite({
    address: currentNetwork?.contractAddress,
    abi: abi1,
    functionName: "createNewCoin",
  });

  useEffect(() => {
    if (deployError) {
      setDeploymentStatus("You rejected Transaction");
      setDeploymentDesc("You canceled the transaction.");
      setDeploymentLoading(false);
    } else if (deployData?.hash) {
      setDeploymentStatus("Transaction sent");
      setDeploymentDesc("Please wait for result.");
      setDeploymentLoading(true);
    }
  }, [deployError, deployData]);

  //Distribution akordiyon
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Anti-bot protection akordiyon

  const [isLimitPerWalletOpen, setIsLimitPerWalletOpen] = useState(false);
  const [isLimitPerTransactionOpen, setIsLimitPerTransactionOpen] =
    useState(false);
  const toggleLimitPerWalletAccordion = () => {
    setIsLimitPerWalletOpen(!isLimitPerWalletOpen);
  };

  const toggleLimitPerTransactionAccordion = () => {
    setIsLimitPerTransactionOpen(!isLimitPerTransactionOpen);
  };

  //tax Akordiyon

  const [isBuyTaxOpen, setIsBuyTaxOpen] = useState(false);

  const toggleBuyTaxAccordion = () => {
    setIsBuyTaxOpen(!isBuyTaxOpen);
  };

  const [isSellTaxOpen, setIsSellTaxOpen] = useState(false);

  const toggleSellTaxAccordion = () => {
    setIsSellTaxOpen(!isSellTaxOpen);
  };

  const [isUniswapOpen, setIsUniswapOpen] = useState(false);

  const toggleIsUniswapAccordion = () => {
    setIsUniswapOpen(!isUniswapOpen);
  };

  const [isBurnOpen, setIsBurnOpen] = useState(false);

  const toggleIsBurnAccordion = () => {
    setIsBurnOpen(!isBurnOpen);
  };

  //sidebar sayfalar ileri geri

  const [step, setStep] = useState(0);

  const handleNext = (e) => {
    e.preventDefault();

    if (
      step === 0 &&
      (!data.tokenname || !data.tokensymbol || !data.totalsupply)
    ) {
      toast.error("Please fill all required fields");
    } else if (step === 1 && isOpen && !data.teamAddress) {
      toast.error("Please fill all required fields");
    } else if (
      step === 3 &&
      (isSellTaxOpen || isBuyTaxOpen) &&
      !data.taxWallet
    ) {
      toast.error("Please fill all required fields");
    } else if (step === 4 && isUniswapOpen && !Number(data.liquidityEth)) {
      toast.error("Please fill all required fields");
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  //------------------------input yönetimi-----------------

  //token info sayfası
  const [data, setData] = useState({
    tokenname: "",
    tokensymbol: "",
    totalsupply: "",
    telegram: "",
    twitter: "",
    website: "",
    description: "",
    teamPercent: "",
    teamAddress: "",
    maxWallet: "",
    maxTx: "",
    buyTax: "",
    sellTax: "",
    taxWallet: "",
    liquidityEth: "",
  });

  const handleChange = (e) => {
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const [tokensToLiq, setTokensToLiq] = useState("0");
  const [tokensToOwner, setTokensToOwner] = useState("0");
  const [tokensToTeam, setTokensToTeam] = useState("0");
  useEffect(() => {
    const getTokensToLiq = () => {
      if (data.totalsupply && data.liquidityEth && isUniswapOpen) {
        if (Number(data.teamPercent) > 0) {
          setTokensToLiq(
            (Number(data.totalsupply) / 100) * (100 - Number(data.teamPercent))
          );
        } else {
          setTokensToLiq(data.totalsupply);
        }
      } else {
        setTokensToLiq(0);
      }
    };

    getTokensToLiq();
  }, [data]);

  const [ethPrice, setEthPrice] = useState(null);

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const url = currentNetwork?.priceApi || networkInfo[0].priceApi;
        const res = await axios.get(url);
        setEthPrice(res.data.USD);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEthPrice();
  }, [currentNetwork]);

  const [usdMCap, setUsdMCap] = useState("");

  useEffect(() => {
    calculateDistribution();
    if (ethPrice && data.liquidityEth) {
      if (data.teamPercent && isOpen) {
        const currentCap = ethPrice * Number(data.liquidityEth);
        const hundredPercent = (currentCap / (100 - data.teamPercent)) * 100;
        setUsdMCap(hundredPercent.toFixed(4));
      } else {
        setUsdMCap(ethPrice * Number(data.liquidityEth).toFixed(4));
      }
    }
  }, [ethPrice, data]);

  const calculateDistribution = () => {
    if (isOpen && isUniswapOpen) {
      if (data.teamPercent && data.totalsupply) {
        const team =
          (Number(data.totalsupply) / 100) * Number(data.teamPercent);
        setTokensToTeam(team.toString());
        setTokensToOwner("0");
      }
    } else if (!isOpen && !isUniswapOpen) {
      if (data.totalsupply) {
        setTokensToOwner(data.totalsupply);
      }
    } else if (isOpen && !isUniswapOpen) {
      if (data.teamPercent && data.totalsupply) {
        const team =
          (Number(data.totalsupply) / 100) * Number(data.teamPercent);
        setTokensToTeam(team.toString());
        const owner = Number(data.totalsupply) - team;
        setTokensToOwner(owner);
      }
    } else if (!isOpen && isUniswapOpen) {
      if (data.totalsupply) {
        setTokensToOwner("0");
      }
    }
  };

  const [constructorArgs, setConstructorArgs] = useState("");
  const [sourceCode, setSourceCode] = useState("");
  const [tokenname, settokenname] = useState("");

  const deploy = async (e) => {
    e.preventDefault();
    settokenname(data.tokenname);
    setscreen((pre) => pre + 1);
    const sourc = getCode(data);
    setSourceCode(sourc);
    const getByte = async (src) => {
      const res = await axios.post(info.apiUrl, { contract: src });
      return res.data.data.contracts["token.sol"].CastCryptoToken.evm.bytecode
        .object;
    };

    const bytecode = await getByte(sourc);

    const teamWallet = isOpen ? data.teamAddress : address;
    const txLimit = isLimitPerTransactionOpen
      ? (Number(data.totalsupply) / 100) * Number(data.maxTx)
      : data.totalsupply;
    const walletLimit = isLimitPerWalletOpen
      ? (Number(data.totalsupply) / 100) * Number(data.maxWallet)
      : data.totalsupply;
    const buyTax = isBuyTaxOpen ? data.buyTax : "0";
    const sellTax = isSellTaxOpen ? data.sellTax : "0";
    const taxWallet = data.taxWallet ? data.taxWallet : address;
    const liqBurn = () => {
      if (isUniswapOpen && isBurnOpen) {
        return true;
      } else {
        return false;
      }
    };

    const liqEth = () => {
      if (isUniswapOpen && Number(data.liquidityEth) > 0) {
        return addDecimal(data.liquidityEth, "18");
      } else {
        return "0";
      }
    };

    const liqTokens = () => {
      if (isUniswapOpen && Number(tokensToLiq)) {
        return addDecimal(tokensToLiq, "18");
      } else {
        return "0";
      }
    };

    const tokensOwner = () => {
      if (Number(tokensToOwner) > 0) {
        return addDecimal(tokensToOwner, "18");
      } else {
        return "0";
      }
    };

    const ethToLiq = liqEth();
    const tokensLiq = liqTokens();
    const ownerTokens = tokensOwner();
    const liqBurning = liqBurn();

    const addresses = [
      taxWallet,
      currentNetwork.routerAddress,
      address,
      teamWallet,
    ];
    const amounts = [
      addDecimal(tokensToTeam, "18"),
      addDecimal(txLimit, "18"),
      addDecimal(walletLimit, "18"),
      buyTax,
      sellTax,
      ethToLiq,
      tokensLiq,
      ownerTokens,
    ];
    const value = () => {
      if (isUniswapOpen && data.liquidityEth > 0) {
        return String(
          Number(addDecimal(data.liquidityEth, "18")) + currentNetwork?.fee
        );
      } else {
        return currentNetwork?.fee.toString();
      }
    };

    const val = value();

    const byt = "0x" + bytecode;

    const args = [
      data.tokenname,
      data.tokensymbol,
      "18",
      addresses,
      amounts,
      liqBurning,
      byt,
    ];
    console.log(args, address, val);
    deployTx({
      args: args,
      from: address,
      value: val,
    });
    const constargs = encodeConstructorArguments(
      data.tokenname,
      data.tokensymbol,
      "18",
      addresses,
      amounts,
      currentNetwork.contractAddress
    );
    setConstructorArgs(constargs);
  };

  const [deployedTokenAddress, setDeployedTokenAddress] = useState("");

  const getTokens = async (contracts) => {
    const res = await multicall({ contracts });
    return res;
  };

  const waitForTransaction = useWaitForTransaction({
    hash: deployData?.hash,
    onSuccess(data) {
      console.log(data);
      const contracts = data.logs.map((e) => ({
        address: e.address,
        abi: erc20ABI,
        functionName: "name",
      }));
      console.log(contracts);
      getTokens(contracts).then((f) => {
        var res = f.filter((e) => e.result == tokenname);
        setDeployedTokenAddress(contracts[f.indexOf(res[0])].address);
        verifyContract(
          contracts[f.indexOf(res[0])].address,
          sourceCode,
          constructorArgs,
          setDeploymentStatus,
          setDeploymentLoading,
          setDeploymentDesc,
          currentNetwork
        );
      });

      setDeploymentStatus(
        `Your Transaction have been confirmed Contract address: ${deployedTokenAddress}`
      );
      setDeploymentDesc(
        "Please wait for source code verification. Do not close window!!!!"
      );
      setDeploymentLoading(true);
    },
    onError(error) {
      setDeploymentStatus("Transaction Not Confirmed. There is an Error.");
      setDeploymentDesc("You can try again or contact our support.");

      setDeploymentLoading(false);
    },
  });

  return (
    <div className="wrapper">
      <div className="shape"></div>
      <div className="container">
        <div className="row"></div>
      </div>
      <section className="bloglist">
        <div className="container">
          <div className="row">
            <SwitchNetwork />

            {screen == 1 && (
              <PageTitle
                statusText={deploymentStatus}
                processName={deploymentDesc}
                deploymentLoading={deploymentLoading}
                deployedTokenAddress={
                  deployedTokenAddress && deployedTokenAddress
                }
                currentNetwork={currentNetwork}
              />
            )}
            {screen == 0 && (
              <>
                <div className="bloglist__main">
                  {/* sidebar */}
                  <Sidebar
                    setStep={setStep}
                    step={step}
                    data={data}
                    isOpen={isOpen}
                    isSellTaxOpen={isSellTaxOpen}
                    isBuyTaxOpen={isBuyTaxOpen}
                    isUniswapOpen={isUniswapOpen}
                  />
                  {/* Token info */}
                  {step === 0 && (
                    <div className="list details">
                      <form action="#" className="form-box">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-14">Create Token</h5>
                          </div>
                          <div className="line"></div>
                        </div>

                        <div className="row">
                          <Input
                            label={"Token Name "}
                            placeholder={"Name of your token "}
                            required={true}
                            name="tokenname"
                            onChange={handleChange}
                            value={data.tokenname}
                          />
                        </div>

                        <div className="row">
                          <Input
                            placeholder="Symbol of your token"
                            required={true}
                            label={<>Token Symbol</>}
                            name="tokensymbol"
                            onChange={handleChange}
                            value={data.tokensymbol}
                          />
                          <div className="col-6">
                            <Input
                              label={"Total Supply "}
                              placeholder={"Token Total Supply"}
                              required={true}
                              name="totalsupply"
                              onChange={handleChange}
                              value={data.totalsupply}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: "8px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    totalsupply: 1000000,
                                  }))
                                }
                                style={{
                                  borderRadius: "20px",
                                  border: "3px solid gray",
                                  padding: "5px",
                                  margin: "2px",
                                  cursor: "pointer",
                                  width: "95%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                1,000,000
                              </div>

                              <div
                                onClick={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    totalsupply: 10000000,
                                  }))
                                }
                                style={{
                                  borderRadius: "20px",
                                  border: "3px solid gray",
                                  padding: "5px",
                                  margin: "2px",
                                  cursor: "pointer",
                                  width: "95%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                10,000,000
                              </div>
                            </span>{" "}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: "8px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    totalsupply: 100000000,
                                  }))
                                }
                                style={{
                                  borderRadius: "20px",
                                  border: "3px solid gray",
                                  padding: "5px",
                                  margin: "2px",
                                  cursor: "pointer",
                                  width: "95%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                100,000,000
                              </div>

                              <div
                                onClick={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    totalsupply: 100000000000,
                                  }))
                                }
                                style={{
                                  borderRadius: "20px",
                                  border: "3px solid gray",
                                  padding: "5px",
                                  margin: "2px",
                                  cursor: "pointer",
                                  width: "95%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                100,000,000,000
                              </div>
                            </span>
                          </div>
                        </div>

                        <InfoInput
                          link={"https://t.me/"}
                          label={"Telegram"}
                          name="telegram"
                          onChange={handleChange}
                          value={data.telegram}
                        />
                        <InfoInput
                          link={" https://x.com/"}
                          label={"Twitter"}
                          name="twitter"
                          onChange={handleChange}
                          value={data.twitter}
                        />
                        <InfoInput
                          link={"https://"}
                          label={"Website"}
                          name="website"
                          onChange={handleChange}
                          value={data.website}
                        />

                        <div className="row">
                          <div className="col mt-4">
                            <label>Short Description</label>
                            <textarea
                              cols="30"
                              rows="10"
                              onFocus={() => setFocusedTextArea(true)}
                              onBlur={() => setFocusedTextArea(false)}
                              style={{
                                background: "transparent",
                                borderRadius: "20px",
                                padding: "12px 15px",
                                fontWeight: "300",
                                fontSize: "14px",
                                fontFamily: '"Readex Pro"',
                                boxShadow: "none",
                                color: "#fff",
                                outline: "none",
                                border: focusedTextArea
                                  ? "7px solid #5c27fe"
                                  : "5px solid gray",
                              }}
                              name="description"
                              onChange={handleChange}
                              value={data.description}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mb-0">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-end">
                              <button
                                className="action-btn"
                                onClick={handleNext}
                              >
                                <span>Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/* Distribution */}
                  {step === 1 && (
                    <div className="list details">
                      <form action="#" className="form-box">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-14">Distribution</h5>
                            <p>
                              Setup how your coin should be initially
                              distributed.
                            </p>
                          </div>
                          <div className="line"></div>
                        </div>
                        <>
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  background: "transparent",
                                  borderRadius: "20px",
                                  color: "#fff",
                                  outline: "none",
                                  border: "4px solid #5c27fe",
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "60px",
                                }}
                                className="mt-5"
                              >
                                <p> Team Allocation</p>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  style={{
                                    width: "80px",
                                    height: "50px",
                                    marginTop: "5px",
                                  }}
                                  onChange={toggleAccordion}
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={isOpen}
                                />
                              </div>
                            </div>
                          </div>

                          {isOpen && (
                            <>
                              <div className="row">
                                <label>Team supply allocation</label>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <input
                                      type="range"
                                      min={0}
                                      max={25}
                                      name="teamPercent"
                                      value={data.teamPercent || 0}
                                      onChange={handleChange}
                                    />
                                    <p> {data?.teamPercent || 0}%</p>
                                  </div>

                                  <div
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <h3>=</h3>
                                  </div>
                                  <Input
                                    label={""}
                                    name={"teamsupply"}
                                    value={
                                      data.totalsupply &&
                                      data.teamPercent &&
                                      (Number(data.totalsupply) / 100) *
                                        data.teamPercent
                                    }
                                    onChange={() => console.log("TV changed")}
                                    disabled={true}
                                    style={{ flex: 1 }}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <Input
                                  label={"Team supply address "}
                                  placeholder={"0x"}
                                  required={true}
                                  name="teamAddress"
                                  value={data.teamAddress}
                                  onChange={handleChange}
                                />
                              </div>
                              <p>
                                The address that will receive the team supply
                                when the token is launched.
                              </p>
                              <br />
                            </>
                          )}
                        </>

                        <div className="row mb-0">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <button
                                className="action-btn"
                                onClick={handlePrev}
                              >
                                <span>Back</span>
                              </button>
                              <button
                                className="action-btn"
                                onClick={handleNext}
                              >
                                <span>Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  <br />

                  {/* Anti-bot */}
                  {step === 2 && (
                    <div className="list details">
                      <form action="#" className="form-box">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-14">Anti-bot Protection</h5>
                            <p>
                              Optionally enable these features to protect your
                              token launch from bots and snipers.
                            </p>
                          </div>
                          <div className="line"></div>
                        </div>

                        <div className="row">
                          <p className="mt-3 mb-3">
                            Once you launch your coin, you can only increase or
                            disable your anti-bot limits.
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "transparent",
                                borderRadius: "20px",
                                color: "#fff",
                                outline: "none",
                                border: "4px solid #5c27fe",
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60px",
                              }}
                              className="mt-5"
                            >
                              <p>Limit Per Wallet</p>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                style={{
                                  width: "80px",
                                  height: "50px",
                                  marginTop: "5px",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={isLimitPerWalletOpen}
                                onChange={toggleLimitPerWalletAccordion}
                              />
                            </div>
                          </div>
                        </div>

                        {isLimitPerWalletOpen && (
                          <>
                            <div className="row">
                              <label>Max tokens per wallet</label>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <input
                                    type="range"
                                    min={0}
                                    max={10}
                                    name="maxWallet"
                                    value={data.maxWallet || 0}
                                    onChange={handleChange}
                                  />
                                  <p> {data?.maxWallet || 0}%</p>
                                </div>
                                <div
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "10px",
                                  }}
                                >
                                  <h3>=</h3>
                                </div>
                                <InfoInput
                                  style={{ flex: 1 }}
                                  disabled={true}
                                  value={
                                    data.totalsupply &&
                                    data.maxWallet &&
                                    (Number(data.totalsupply) / 100) *
                                      Number(data.maxWallet)
                                  }
                                />
                              </div>
                            </div>
                            <p>
                              The maximum share of the total supply a single
                              wallet address can hold
                            </p>
                            <br />
                          </>
                        )}

                        <div className="row">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "transparent",
                                borderRadius: "20px",
                                color: "#fff",
                                outline: "none",
                                border: "4px solid #5c27fe",
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60px",
                              }}
                              className="mt-5"
                            >
                              <p>Limit Per Transaction</p>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                style={{
                                  marginTop: "5px",
                                  width: "80px",
                                  height: "50px",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={isLimitPerTransactionOpen}
                                onChange={toggleLimitPerTransactionAccordion}
                              />
                            </div>
                          </div>
                        </div>

                        {isLimitPerTransactionOpen && (
                          <>
                            <div className="row">
                              <label>Max tokens per transaction</label>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <div>
                                  <input
                                    type="range"
                                    min={0}
                                    max={10}
                                    name="maxTx"
                                    value={data.maxTx || 0}
                                    onChange={handleChange}
                                  />
                                  <p> {data?.maxTx || 0}%</p>
                                </div>
                                <div
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "10px",
                                  }}
                                >
                                  <h3>=</h3>
                                </div>
                                <InfoInput
                                  placeholder={""}
                                  disabled={true}
                                  value={
                                    data.totalsupply &&
                                    data.maxTx &&
                                    (Number(data.totalsupply) / 100) *
                                      Number(data.maxTx)
                                  }
                                />
                              </div>
                            </div>
                            <p>
                              The maximum number of tokens that can be traded in
                              a single trade
                            </p>{" "}
                            <br />
                          </>
                        )}

                        <div className="row mb-0">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <button
                                className="action-btn"
                                onClick={handlePrev}
                              >
                                <span>Back</span>
                              </button>
                              <button
                                className="action-btn"
                                onClick={handleNext}
                              >
                                <span>Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  <br />

                  {/* taxes */}
                  {step === 3 && (
                    <div className="list details">
                      <form action="#" className="form-box">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-14">Coin trade taxes</h5>
                            <p>
                              Optionally add a buy and/or sell tax on Dex
                              trades. Taxes are auto-sold for Native Currency at
                              regular intervals.
                            </p>
                          </div>
                          <div className="line"></div>
                        </div>
                        <>
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  background: "transparent",
                                  borderRadius: "20px",
                                  color: "#fff",
                                  outline: "none",
                                  border: "4px solid #5c27fe",
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "60px",
                                }}
                                className="mt-5"
                              >
                                <p>Buy Tax</p>
                              </div>

                              <div className="form-check form-switch">
                                <input
                                  style={{
                                    marginTop: "10px",
                                    width: "80px",
                                    height: "50px",
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={isBuyTaxOpen}
                                  onChange={toggleBuyTaxAccordion}
                                />
                              </div>
                            </div>
                          </div>

                          {isBuyTaxOpen && (
                            <>
                              <div className="row">
                                <div style={{ marginTop: "10px" }}>
                                  <div>
                                    <input
                                      type="range"
                                      min={0}
                                      max={25}
                                      name="buyTax"
                                      value={data.buyTax || 0}
                                      onChange={handleChange}
                                    />
                                    <p> {data?.buyTax || 0}%</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>

                        <div className="row">
                          <br />
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "transparent",
                                borderRadius: "20px",
                                color: "#fff",
                                outline: "none",
                                border: "4px solid #5c27fe",
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60px",
                              }}
                              className="mt-5"
                            >
                              <p>Sell Tax</p>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                style={{
                                  marginTop: "10px",
                                  width: "80px",
                                  height: "50px",
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={isSellTaxOpen}
                                onChange={toggleSellTaxAccordion}
                              />
                            </div>
                          </div>
                        </div>

                        {isSellTaxOpen && (
                          <>
                            <div className="row">
                              <div style={{ gap: "10px", marginTop: "10px" }}>
                                {/* <InfoInput
                                  link={"%"}
                                  label={
                                    <>
                                      Sell Tax
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          display: "inline-block",
                                          borderRadius: "10px",
                                        }}
                                        title="Tax token on token sells."
                                      >
                                        <FaQuestionCircle
                                          style={{
                                            color: "#007bff",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </span>
                                    </>
                                  }
                                  placeholder={"0"}
                                  name="sellTax"
                                  value={data.sellTax}
                                  onChange={handleChange}
                                /> */}

                                <div>
                                  <input
                                    type="range"
                                    min={0}
                                    max={25}
                                    name="sellTax"
                                    value={data.sellTax || 0}
                                    onChange={handleChange}
                                  />
                                  <p> {data?.sellTax || 0}%</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {(isSellTaxOpen || isBuyTaxOpen) && (
                          <>
                            <div className="row">
                              <Input
                                label={"Tax payout address "}
                                placeholder={"0x"}
                                required={true}
                                name="taxWallet"
                                value={data.taxWallet}
                                onChange={handleChange}
                              />
                            </div>
                            <p>
                              This is the address to which earned taxes will be
                              paid out.
                            </p>
                            <br />
                          </>
                        )}

                        <div className="row mb-0">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <button
                                className="action-btn"
                                onClick={handlePrev}
                              >
                                <span>Back</span>
                              </button>
                              <button
                                className="action-btn"
                                onClick={handleNext}
                              >
                                <span>Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  <br />

                  {/* liquidity */}
                  {step === 4 && (
                    <div className="list details">
                      <form action="#" className="form-box">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-14">Initial liquidity</h5>
                            <p>
                              Pair the remainder of your token supply with an
                              amount of{" "}
                              {currentNetwork?.currencySymbol || "ETH"} you
                              choose to set up the initial liquidity pool. You
                              can do this after your token is launched, but we
                              recommend doing it when you launch your token.
                            </p>
                          </div>
                          <div className="line"></div>
                        </div>
                        <>
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  background: "transparent",
                                  borderRadius: "20px",
                                  color: "#fff",
                                  outline: "none",
                                  border: "4px solid #5c27fe",
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  minHeight: "60px",
                                  padding: "2px",
                                }}
                                className="mt-5"
                              >
                                <p className="text-center">
                                  Fund the{" "}
                                  {currentNetwork?.exchangeName || "Uniswap"}{" "}
                                  pool on launch
                                </p>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  style={{
                                    marginTop: "10px",
                                    width: "80px",
                                    height: "50px",
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={isUniswapOpen}
                                  onChange={toggleIsUniswapAccordion}
                                />
                              </div>
                            </div>
                          </div>

                          {isUniswapOpen && (
                            <>
                              <div className="row">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <label>
                                    Deposit{" "}
                                    {currentNetwork?.currencySymbol || "ETH"}{" "}
                                  </label>
                                  <label>Deposit Tokens</label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Input
                                    placeholder={"0"}
                                    name="liquidityEth"
                                    value={data.liquidityEth}
                                    onChange={handleChange}
                                  />
                                  <Input disabled={true} value={tokensToLiq} />
                                </div>
                              </div>

                              <div className="row">
                                <div
                                  style={{
                                    background: "transparent",
                                    borderRadius: "20px",
                                    color: "#fff",
                                    outline: "none",
                                    border: "4px solid #5c27fe",
                                    width: "80%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "60px",
                                    padding: "2px",
                                  }}
                                  className="mt-5"
                                >
                                  Marketcap at launch{" " + usdMCap + "$"}
                                </div>
                              </div>
                              <br />
                            </>
                          )}

                          {isUniswapOpen && (
                            <>
                              <div className="row">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      background: "transparent",
                                      borderRadius: "20px",
                                      color: "#fff",
                                      outline: "none",
                                      border: "4px solid #5c27fe",
                                      width: "80%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "60px",
                                      padding: "2px",
                                    }}
                                    className="mt-5"
                                  >
                                    <p> Burn Liquidity</p>
                                  </div>
                                  <div className="form-check form-switch">
                                    <input
                                      style={{
                                        marginTop: "10px",
                                        width: "80px",
                                        height: "50px",
                                      }}
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={isBurnOpen}
                                      onChange={toggleIsBurnAccordion}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <p>
                                  LP tokens will be burnt when funding, and
                                  initial liquidity will be locked forever.
                                </p>
                              </div>
                              <br />
                            </>
                          )}
                          {isBurnOpen && isUniswapOpen && (
                            <>
                              <div className="row">
                                <h5 style={{ color: "red" }}>
                                  Please make sure what you are doing, checking
                                  this option will burn your liquidity tokens
                                  and it is unrevertable.
                                </h5>
                              </div>
                              <br />
                            </>
                          )}
                        </>
                        <div className="row mb-0">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <button
                                className="action-btn"
                                onClick={handlePrev}
                              >
                                <span>Back</span>
                              </button>
                              <button
                                className="action-btn"
                                onClick={handleNext}
                              >
                                <span>Review </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {step === 5 && (
                    <div className="list details">
                      <section className="portfolio">
                        <div className="shape"></div>
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="block-text center">
                                <h5 className="sub-heading">
                                  <span>Ready to Review?</span>
                                </h5>
                                <h5>
                                  Almost there! Track your coin builder progress
                                  and once finished you're all good to launch.
                                </h5>
                                <br />
                                <br />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="portfolio__left">
                                <div className="portfolio-box">
                                  <div className="content">
                                    <h4 className="title "> Token Info </h4>
                                    <hr />
                                    <h5 className="title">Token Name</h5>
                                    <h5>{data.tokenname || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Token Symbol</h5>
                                    <h5>{data.tokensymbol || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Total Supply</h5>
                                    <h5>{data.totalsupply || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Telegram</h5>
                                    <h5>{data.telegram || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Twitter</h5>
                                    <h5>{data.twitter || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Web Site</h5>
                                    <h5>{data.website || "-"}</h5>
                                    <hr />
                                    <h5 className="title">Description</h5>
                                    <p>{data.description || "-"}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="portfolio__left">
                                <div className="portfolio-box">
                                  <div className="content">
                                    <h4 className="title "> Distribution</h4>
                                    <hr />
                                    <h5 className="title">Team allocation</h5>
                                    <h5> {isOpen ? data.teamPercent : "-"}</h5>
                                    <hr />
                                    <h5 className="title">Team address</h5>
                                    <p style={{ fontSize: "0.8rem" }}>
                                      {isOpen ? data.teamAddress : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="portfolio__left">
                                <div className="portfolio-box">
                                  <div className="content">
                                    <h4 className="title">
                                      Anti-bot Protection
                                    </h4>
                                    <hr />
                                    <h5 className="title">
                                      Max tokens per wallet
                                    </h5>
                                    <h5>
                                      {isLimitPerWalletOpen
                                        ? data.maxWallet
                                        : data.totalsupply}
                                    </h5>
                                    <hr />
                                    <h5 className="title">Max tokens per tx</h5>
                                    <h5>
                                      {isLimitPerTransactionOpen
                                        ? data.maxTx
                                        : data.totalsupply}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="portfolio__left">
                                <div className="portfolio-box">
                                  <div className="content">
                                    <h4 className="title "> Tax</h4>
                                    <hr />
                                    <h5 className="title">Buy Tax</h5>
                                    <h5> {isBuyTaxOpen ? data.buyTax : "0"}</h5>
                                    <hr />
                                    <h5 className="title">Sell Tax</h5>
                                    <h5>
                                      {isSellTaxOpen ? data.sellTax : "0"}
                                    </h5>
                                    <hr />
                                    <h5 className="title">Tax Wallet</h5>
                                    <p style={{ fontSize: "0.8rem" }}>
                                      {isBuyTaxOpen || isSellTaxOpen
                                        ? data.taxWallet
                                        : address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="portfolio__left">
                                <div className="portfolio-box">
                                  <div className="content">
                                    <h4 className="title"> Liquidity</h4>

                                    <h5 className="title">Liquidity</h5>
                                    <h5>
                                      {isUniswapOpen
                                        ? data.liquidityEth +
                                          (currentNetwork?.currencySymbol ||
                                            " ETH")
                                        : 0 +
                                          (currentNetwork?.currencySymbol ||
                                            " ETH")}
                                    </h5>
                                    <hr style={{ width: "100%" }} />
                                    <h5 className="title">Fee</h5>
                                    <h5>
                                      {removeDecimal(currentNetwork.fee, "18") +
                                        (currentNetwork?.currencySymbol ||
                                          " ETH")}
                                    </h5>
                                    <hr />
                                    <h5 className="title">Total Eth</h5>
                                    <h5>
                                      {isUniswapOpen
                                        ? Number(
                                            removeDecimal(
                                              currentNetwork.fee,
                                              "18"
                                            )
                                          ) +
                                          Number(data.liquidityEth) +
                                          (currentNetwork?.currencySymbol ||
                                            " ETH")
                                        : removeDecimal(
                                            currentNetwork.fee,
                                            "18"
                                          ) +
                                          (currentNetwork?.currencySymbol ||
                                            " ETH")}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div className="row mb-0">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <button className="action-btn" onClick={handlePrev}>
                              <span>Back</span>
                            </button>
                            {address ? (
                              <button className="action-btn" onClick={deploy}>
                                <span>Deploy </span>
                              </button>
                            ) : (
                              <>
                                <ConnectButton />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <br />
    </div>
  );
}

export default BlogDetails;
